const ID_SELECTOR = "input[data-tracking-id]"
const ID_PARAM = "dtid"
const ID_STORAGE = "tracking__dtid"

export function useTracking (document) {
  const trackingId = retrieveTrackingId(document)

  if (!trackingId) {
    return
  }

  const inputs = document.querySelectorAll(ID_SELECTOR)
  inputs.forEach(input => input.value = trackingId)
}

function retrieveTrackingId (document) {
  const searchParams = new URLSearchParams(document.location.search)

  if (searchParams.has(ID_PARAM)) {
    sessionStorage.setItem(ID_STORAGE, searchParams.get(ID_PARAM))
    
    return searchParams.get(ID_PARAM)
  }
  else if (sessionStorage.getItem(ID_STORAGE)) {
    return sessionStorage.getItem(ID_STORAGE)
  }
  else {
    return null
  }
}
