export function useInfiniteScroll (document) {
  const SELECTOR = "[data-infinite-scroll]"
  const containers = document.querySelectorAll(SELECTOR)
  containers.forEach(cloneContainer)
}

function cloneContainer (container) {
  const clone = container.cloneNode(true)
  container.parentNode.appendChild(clone)
}
