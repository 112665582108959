const SELECTOR = "[data-webhook-form]"
const WEBBOOK_URL_ATTRIBUTE = "data-webhook-url"
const REDIRECT_URL_ATTRIBUTE = "data-redirect-url"
const LOADING_SELECTOR = "[data-webhook-loading]"
const ERROR_SELECTOR = "[data-webhook-error]"
const SUCCESS_SELECTOR = "[data-webhook-success]"
const INFO_SELECTOR = "[data-webhook-info]"

export function useWebhookForms (document) {
  const forms = document.querySelectorAll(SELECTOR)
  forms.forEach(form => form.addEventListener('submit', handle))

  function handle (event) {
    event.preventDefault()
  
    const form = event.target
    const submitter = event.submitter
    const loading = form.querySelector(LOADING_SELECTOR)
    const error = form.querySelector(ERROR_SELECTOR)
    const success = form.querySelector(SUCCESS_SELECTOR)
    const info = form.querySelector(INFO_SELECTOR)

    const webhookUrl = form.getAttribute(WEBBOOK_URL_ATTRIBUTE)
    const redirectUrl = form.getAttribute(REDIRECT_URL_ATTRIBUTE)
    
    const data = new FormData(form)

    error && (error.style.display = 'none')
    success && (success.style.display = 'none')
    info && (info.style.display = 'none')
    loading && (loading.style.display = 'block')
    submitter.disabled = true

    fetch(webhookUrl, { method: "POST", body: data })
      .catch(error => {
        console.error(error)

        return { ok: false }
      })
      .then(res => {
        if (res.ok && redirectUrl) {
          setTimeout(
            () => {
              form.reset()
              loading && (loading.style.display = 'none')
              info && (info.style.display = 'block')
              submitter.disabled = false
              document.location.href = redirectUrl
            }, 
            100
          )
        }
        else if (res.ok) {
          form.reset()
          loading && (loading.style.display = 'none')
          success && (success.style.display = 'block')
          submitter.disabled = false
        }
        else {
          loading && (loading.style.display = 'none')
          error && (error.style.display = 'block')
          submitter.disabled = false
        }
      })
  }
}
